/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants/index';
import { DEFAULT_PATHS } from 'config.js';

const blog = lazy(() => import('views/blog/Blog'));

const dashboard = {
  gettingStarted: lazy(() => import('views/dashboard/DashboardGettingStarted')),
  analysis: lazy(() => import('views/dashboard/DashboardAnalysis')),
  messages: lazy(() => import('views/support/SupportTickets')),
  // crm: lazy(() => import('views/support/SupportTickets')),
  supportTicketDetail: lazy(() => import('views/support/SupportTicketsDetail')),
  salesAnalysis: lazy(() => import('views/dashboard/SalesAnalysis')),
  deliveryMonitoring: lazy(() => import('views/dashboard/DeliveryMonitoring')),
  delayedConfirmation: lazy(() => import('views/dashboard/DelayedConfirmation')),
  productAnalysis: lazy(() => import('views/dashboard/ProductAnalysis')),
  productMonitoring: lazy(() => import('views/dashboard/ProductMonitoring')),
  orderMonitoring: lazy(() => import('views/dashboard/OrderMonitoring')),
  accountHealth: lazy(() => import('views/dashboard/AccountHealth')),
  deliverySheet: lazy(() => import('views/orders/DeliveryTrack')),
};
const orders = {
  views: lazy(() => import('views/orders/OrdersView')),
  detail: lazy(() => import('views/orders/OrdersViewDetail')),
};
const returns = {
  views: lazy(() => import('views/returns/ReturnsView')),
  detail: lazy(() => import('views/returns/ReturnsViewDetail')),
};
const statements = {
  index: lazy(() => import('views/statements/StatementViewsV2')),
  overview: lazy(() => import('views/statements/StatementsDashboard')),
  transactions: lazy(() => import('views/statements/Transactions')),
  orders: lazy(() => import('views/statements/StatementOrders')),
};
const reports = {
  index: lazy(() => import('views/reports/ReportViews')),
};
const catalog = {
  products: lazy(() => import('views/catalog/List')),
  pendingProducts: lazy(() => import('views/catalog/List')),
  imageProducts: lazy(() => import('views/catalog/ImageList')),
  allImages: lazy(() => import('views/catalog/ImageList/RejectedMedias')),
  offers: lazy(() => import('views/catalog/Offers')),
  offerCreate: lazy(() => import('views/catalog/Offers/Create')),
  offerDetail: lazy(() => import('views/catalog/Offers/Detail')),
  databaseAdd: lazy(() => import('views/catalog/ServicesDatabaseAdd')),
  productDetail: lazy(() => import('views/catalog/detail/ProductsDetail')),
  productFieldReceommendation: lazy(() => import('views/catalog/detail/ProductFieldReceommendation')),
  categories: lazy(() => import('views/catalog/categories/index')),
  brands: lazy(() => import('views/catalog/brands/index')),
  fieldsList: lazy(() => import('views/catalog/fields/FieldList')),
  fieldsDetail: lazy(() => import('views/catalog/fields/FieldDetail')),
  fieldSetList: lazy(() => import('views/catalog/fieldSets/FieldSetList')),
  fieldSetDetail: lazy(() => import('views/catalog/fieldSets/FieldSetDetail')),
  hosting: lazy(() => import('views/catalog/ServicesHosting')),
  magentoSync: lazy(() => import('views/catalog/MagentoSync')),
  feed: lazy(() => import('views/catalog/Feed')),
  users: lazy(() => import('views/catalog/ServicesUsers')),
  inventory: lazy(() => import('views/catalog/inventory/list')),
  inventoryDetail: lazy(() => import('views/catalog/inventory/inventoryDetail')),
  imports: lazy(() => import('views/catalog/ProductImports')),
  importHistory: lazy(() => import('views/catalog/ImportHistory')),
  auditLog: lazy(() => import('views/catalog/AuditLog')),
  productRatingsAndReviews: lazy(() => import('views/catalog/ratingReview/ProductRatingsAndReviews')),
  questionAnswer: lazy(() => import('views/catalog/questionAnswer/QuestionAnswer')),
  studioImages: lazy(() => import('views/catalog/ImageList/StudioImages')),
  unlistedStudioProducts: lazy(() => import('views/catalog/ImageList/UnlistedStudioProducts')),
  // ideaImages: lazy(() => import('views/catalog/ImageList/IdeaImages')),
  ideaList: lazy(() => import('views/catalog/ideas/IdeaList')),
  ideaDetails: lazy(() => import('views/catalog/ideas/IdeaDetails')),
};
const sellers = {
  list: lazy(() => import('views/sellers/SellerList')),
  audit: lazy(() => import('views/sellers/SellerAudit')),
  errors: lazy(() => import('views/sellers/ErrorAudit')),
  detail: lazy(() => import('views/sellers/SellerDetail')),
};
const account = {
  settings: lazy(() => import('views/account/AccountSettings')),
  billing: lazy(() => import('views/account/AccountBilling')),
  security: lazy(() => import('views/account/AccountSecurity')),
};
const support = {
  docs: lazy(() => import('views/support/SupportDocs')),
  docsDetail: lazy(() => import('views/support/SupportDocsDetail')),
  knowledgeBase: lazy(() => import('views/support/SupportKnowledgeBase')),
  tickets: lazy(() => import('views/support/SupportTickets')),
  ticketsDetail: lazy(() => import('views/support/SupportTicketsDetail')),
};

const promotions = {
  list: lazy(() => import('views/promotions/PromotionList')),
  coupons: lazy(() => import('views/promotions/CouponList')),
  detail: lazy(() => import('views/promotions/PromotionDetail')),
  couponDetail: lazy(() => import('views/promotions/CouponDetail')),
  couponLogs: lazy(() => import('views/promotions/CouponLogs')),
};

const consignments = {
  lpoList: lazy(() => import('views/consignments/ConsignmentView')),
  lpoDetails: lazy(() => import('views/consignments/LpoDetails')),
};

const penalty = {
  penaltyList: lazy(() => import('views/penalty/PenaltyList')),
};
const marketing = {
  adsPlatform: lazy(() => import('views/marketing/AdsPlatform')),
  adsCampaignDetails: lazy(() => import('views/marketing/AdsCampaignDetails')),
};

// const b2bPromotions = {
//   bannerSlots: lazy(() => import('views/b2b-promotions/BannerSlots')),
//   bannersPendingApproval: lazy(() => import('views/b2b-promotions/BannersPendingApproval')),
// };
const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = ({ orderStatusSummary = {}, currentUser } = {}) => {
  console.log('orderStatusSummary', orderStatusSummary, currentUser);
  let defaultRoute = `${appRoot}/dashboard/getting-started`;
  if (currentUser?.role === USER_ROLE.WarehouseTeam || currentUser?.role === USER_ROLE.CatalogTeam) {
    defaultRoute = `${appRoot}/catalog`;
  } else if (currentUser?.role === USER_ROLE.Audit) {
    defaultRoute = `${appRoot}/statements/dashboard`;
  }
  return {
    mainMenuItems: [
      {
        path: DEFAULT_PATHS.APP,
        exact: true,
        redirect: true,
        to: defaultRoute,
        // to: currentUser?.role === USER_ROLE.WarehouseTeam ? `${appRoot}/catalog` : `${appRoot}/dashboard/getting-started`,
      },
      {
        path: DEFAULT_PATHS.APP,
        exact: true,
        redirect: true,
        to: `${appRoot}/sellers/list`,
      },

      {
        path: `${appRoot}/catalog`,
        component: blog,
        label: 'menu.catalog',
        icon: 'file-text',
      },

      {
        path: `${appRoot}/orders`,
        label: 'menu.orders',
        icon: 'dollar',
        // exact: true,
        redirect: true,
        component: orders.views,
        to: `${appRoot}/orders/views`,
      },
      // {
      //   path: `${appRoot}/reports`,
      //   label: 'menu.reports',
      //   icon: 'messages',
      //   component: reports.index,
      // },
      {
        path: `${appRoot}/statements`,
        label: 'Statements',
        roles: [USER_ROLE.SuperAdmin, USER_ROLE.Accounts, USER_ROLE.CustomerSupport, USER_ROLE.Finance, USER_ROLE.Audit],
        component: statements.overview,
        redirect: true,
        exact: true,
        to: `${appRoot}/statements/overview`,
      },
      {
        path: `${appRoot}/catalog/inventory`,

        label: 'menu.warehouse',
        icon: 'trend-up',
        component: catalog.inventory,
      },
      {
        path: `${appRoot}/promotions`,

        label: 'Promotions',
        icon: 'divide',
        redirect: true,
        to: `${appRoot}/promotions/list`,
      },
      {
        path: `${appRoot}/studio`,
        label: 'Studio',
        icon: 'camera',
        component: blog,
      },
      {
        path: `${appRoot}/penalty`,
        label: 'Penalties',
        icon: 'anchor',
        component: penalty.penaltyList,
      },

      // {
      //   path: `${appRoot}/consignments`,
      //   label: 'Consignments',
      //   icon: 'dollar',
      //   // exact: true,
      //   redirect: true,
      //   component: consignments.views,
      //   to: `${appRoot}/consignments/views`,
      // },
      // {
      //   path: `${appRoot}/consignments`,
      //   label: 'Consignments',
      //   icon: 'dollar',
      //   // exact: true,
      //   redirect: true,
      //   component: consignments.views,
      //   to: `${appRoot}/consignments/views`,
      // },
      // {
      //   path: `${appRoot}/b2b-promotions`,
      //   label: 'B2B Promotions',
      //   icon: 'trend-up',
      //   component: b2bPromotions.bannerSlots,
      // },
    ],
    sidebarItems: [
      {
        path: `${appRoot}/dashboard`,
        label: 'menu.dashboard',
        icon: 'grid-1',
        exact: true,
        redirect: true,
        to:
          currentUser?.role === USER_ROLE.WarehouseTeam || currentUser?.role === USER_ROLE.CatalogTeam
            ? `${appRoot}/catalog`
            : `${appRoot}/dashboard/getting-started`,
        subs: [
          { path: '/getting-started', label: 'menu.dashboard', icon: 'navigate-diagonal', component: dashboard.gettingStarted },
          { path: '/ratings-and-reviews', label: 'menu.ratings-and-reviews', icon: 'star', component: catalog.productRatingsAndReviews },
          {
            path: '/messages',
            label: 'Support Tickets',
            icon: 'email',
            exact: true,
            component: dashboard.messages,
            subs: [{ path: '/detail/:id', exact: true, label: 'menu.messages', icon: 'email', hideInMenu: true, component: dashboard.supportTicketDetail }],
          },
          // {
          //   path: '/crm',
          //   label: 'CRM',
          //   icon: 'email',
          //   exact: true,
          //   component: dashboard.messages,
          //   subs: [{ path: '/detail/:id', exact: true, label: 'menu.messages', icon: 'email', hideInMenu: true, component: dashboard.supportTicketDetail }],
          // },
          { path: '/sales-analysis', label: 'menu.sales_analysis', icon: 'chart-4', component: dashboard.salesAnalysis },
          { path: '/delivery-monitoring', label: 'Delivery Monitoring', icon: 'chart-4', component: dashboard.deliveryMonitoring },
          { path: '/delayed-confirmation', label: 'Delayed Confirmation', icon: 'chart-4', component: dashboard.delayedConfirmation },
          { path: '/product-analysis', label: 'menu.product_analysis', icon: 'chart-2', component: dashboard.productAnalysis },
          { path: '/order-monitoring', label: 'menu.order_monitoring', icon: 'chart-3', component: dashboard.orderMonitoring },
          { path: '/product-monitoring', label: 'menu.product_monitoring', icon: 'chart-4', component: dashboard.productMonitoring },
          {
            path: '/account-health',
            label: 'Account Health',
            icon: 'chart-4',
            component: dashboard.accountHealth,
            // subs: [{ path: '/detail/:sellerId', exact: true, label: 'Account Health', icon: 'navigate-diagonal', component: dashboard.accountHealth }],
          },
          {
            path: '/delivery-track-sheet',
            label: `Delivery Track Sheet`,
            icon: 'delivery_truck',
            exact: true,
            component: dashboard.deliverySheet,
          },
          {
            path: '/delivery-track-sheet-easy-ship',
            label: `Delivery Track(Easy Ship)`,
            icon: 'delivery_truck',
            exact: true,
            component: dashboard.deliverySheet,
          },
        ],
      },
      {
        path: `${appRoot}/sellers`,
        label: 'menu.sellers',
        icon: 'grid-1',
        exact: true,
        roles: [USER_ROLE.SuperAdmin, USER_ROLE.Accounts, USER_ROLE.CustomerSupport, USER_ROLE.Finance],
        subs: [
          { path: '/list', label: 'seller.list', icon: 'navigate-diagonal', component: sellers.list },
          { path: '/audit', label: 'seller.audit', icon: 'navigate-diagonal', component: sellers.audit },
          { path: '/detail/:id', label: 'seller.details', hideInMenu: true, component: sellers.detail },
        ],
      },

      {
        path: `${appRoot}/catalog`,
        label: 'menu.company_catalog',
        icon: 'grid-1',
        exact: true,
        roles: [USER_ROLE.SuperAdmin, USER_ROLE.CustomerSupport, USER_ROLE.ContentTeamAdmin, USER_ROLE.WarehouseTeam, USER_ROLE.CatalogTeam],
        redirect: true,
        to: currentUser?.role === USER_ROLE.WarehouseTeam ? `${appRoot}/catalog/pending-products` : `${appRoot}/catalog/partner`,

        showWhen: (pathname) => {
          return pathname?.indexOf('catalog') > -1;
        },
        // redirect: true,
        // to: `${appRoot}/catalog/partner`,

        subs: [
          {
            path: `/pending-approvals`,
            label: 'menu.pending_approval',
            icon: 'check',
            component: catalog.products,
          },
          {
            path: `/pending-image-approvals`,
            label: 'menu.pending_media_approval',
            icon: 'check',
            component: catalog.imageProducts,
          },

          {
            path: `/pending-products`,
            label: 'Pending Updates(CBM and Fixing Time)',
            icon: 'database',
            component: catalog.products,
          },
          {
            path: `/all-products`,
            label: 'menu.all_products',
            icon: 'database',
            component: catalog.products,
          },
          {
            path: '/partner',
            label: 'menu.products',
            icon: 'database',
            component: catalog.products,
            subs: [
              { path: '/add', label: 'menu.database-add', hideInMenu: true, component: catalog.databaseAdd },
              { path: '/detail/:id', label: 'menu.database-detail', hideInMenu: true, component: catalog.productDetail },
            ],
          },
          {
            path: `/rejected-images`,
            label: 'Rejected Images',
            icon: 'image',
            component: catalog.allImages,
          },

          {
            path: '/offers',
            label: 'menu.offers',
            icon: 'dollar',
            component: catalog.offers,
            subs: [
              { path: '/detail/:offerListId', label: 'menu.database-add', hideInMenu: true, component: catalog.offerDetail },
              { path: '/create', label: 'menu.database-add', hideInMenu: true, component: catalog.offerCreate },
            ],
          },
          { path: '/categories', label: 'menu.company_categories', icon: 'bookmark', component: catalog.categories },
          { path: '/brands', label: 'menu.brand_management', icon: 'dollar', component: catalog.brands },
          {
            path: '/fields',
            label: 'menu.fields',
            icon: 'box',
            exact: true,
            component: catalog.fieldsList,
            subs: [{ path: '/detail/:id', exact: true, label: 'menu.database-detail', hideInMenu: true, component: catalog.fieldsDetail }],
          },
          {
            path: '/field-sets',
            label: 'menu.field_sets',
            icon: 'box',
            component: catalog.fieldSetList,
            subs: [{ path: '/detail/:id', label: 'menu.database-detail', hideInMenu: true, component: catalog.fieldSetDetail }],
          },
          {
            path: '/inventory',
            label: 'menu.inventory',
            icon: 'box',
            component: catalog.inventory,
            subs: [{ path: '/detail/:id', label: 'menu.database-detail', hideInMenu: true, component: catalog.inventoryDetail }],
          },
          { path: '/import', label: 'menu.import', icon: 'cloud-upload', component: catalog.imports },
          { path: '/import-history', label: 'menu.import_history', icon: 'clock', component: catalog.importHistory },
          // { path: '/exports', label: 'menu.exports', icon: 'cloud-download', component: catalog.hosting },
          { path: '/audit-logs', label: 'menu.activity', icon: 'shield', component: catalog.auditLog },
          { path: '/magento-sync', label: 'menu.magentoSync', icon: 'shuffle', component: catalog.magentoSync },
          { path: '/product-feed', label: 'menu.product-feed', icon: 'shuffle', component: catalog.feed },
          { path: '/ratings-and-reviews', label: 'menu.ratings-and-reviews', icon: 'star', component: catalog.productRatingsAndReviews },
          { path: '/question-and-answer', label: 'menu.question-and-answer', icon: 'question-hexagon', component: catalog.questionAnswer },
          {
            path: '/ideas',
            label: 'Ideas',
            icon: 'user',
            exact: true,
            component: catalog.ideaList,
            subs: [{ path: '/detail/:id', exact: true, label: 'menu.database-detail', hideInMenu: true, component: catalog.ideaDetails }],
          },
          {
            path: `/archive-products`,
            label: 'Archive products',
            icon: 'database',
            component: catalog.products,
          },
        ],
      },

      {
        path: `${appRoot}/orders`,
        label: 'menu.orders',

        exact: true,
        redirect: true,
        icon: 'bookmark',
        //  hideInMenu: false,
        showWhen: (pathname) => {
          return pathname?.indexOf('orders') > -1 || pathname?.indexOf('returns') > -1;
        },
        to: `${appRoot}/orders/views/all`,
        subs: [
          {
            path: '/views/all',
            label: `All Orders (${orderStatusSummary?.[20] || 0})`,
            // icon: 'orders',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/pending',
            label: `Pending (${orderStatusSummary?.[0] || 0})`,
            // // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/packing',
            label: `Accepted (${orderStatusSummary?.[1] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/shipping_pending',
            label: `Ready To Pickup (${orderStatusSummary?.[2] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/shipped',
            label: `Shipped/Picked (${orderStatusSummary?.[3] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/out_for_delivery',
            label: `Out for Delivery (${orderStatusSummary?.[11] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/delivered',
            label: `Delivered (${orderStatusSummary?.[4] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          // {
          //   path: '/views/invoice_pending',
          //   label: `Invoice Pending (${orderStatusSummary?.[9] || 0})`,
          //   // icon: 'bookmark',
          //   component: orders.views,
          //   exact: true,
          // },
          {
            path: '/views/completed',
            label: `Completed (${orderStatusSummary?.[8] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/undelivered',
            label: `Undelivered (${orderStatusSummary?.[10] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/rejected',
            label: `Rejected (${orderStatusSummary?.[6] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/cancelled',
            label: `Cancelled (${orderStatusSummary?.[15] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },

          { path: '/detail/:id', label: 'menu.orders-detail', icon: 'file-empty', component: orders.detail, hideInMenu: true },
        ],
      },
      {
        path: `${appRoot}/returns`,
        label: 'RETURNS',
        icon: 'user',
        exact: true,
        redirect: true,
        //  hideInMenu: false,
        showWhen: (pathname) => {
          return pathname?.indexOf('orders') > -1 || pathname?.indexOf('returns') > -1;
        },
        to: `${appRoot}/returns/views`,
        subs: [
          {
            path: `${appRoot}/returns`,
            label: `Returns (${orderStatusSummary?.[25] || 0})`,
            icon: 'trend-up',
            exact: true,
            component: returns.views,
            to: `${appRoot}/returns/views`,
          },
          { path: '/detail/:id', label: 'menu.orders-detail', icon: 'file-empty', component: returns.detail, hideInMenu: true },
        ],
      },

      {
        path: `${appRoot}/statements`,
        label: 'menu.statements',
        icon: 'user',
        exact: true,
        redirect: true,
        showWhen: (pathname) => {
          return pathname.includes('statements');
        },
        to: `${appRoot}/statements/dashboard`,
        subs: [
          { path: '/dashboard', label: 'Dashboard', icon: 'building', iconSize: 16, component: statements.overview, exact: true },

          {
            path: '/transactions',
            label: 'Transactions',
            icon: 'building',
            component: statements.transactions,
            exact: true,
            hideInMenu: true,
            subs: [
              {
                path: '/detail/:statementId',
                label: 'Statement Period - ',
                hideInMenu: true,
                icon: 'building',
                iconSize: 16,
                component: statements.transactions,
                exact: true,
              },
            ],
          },
          {
            path: '/current-settlement',
            label: 'Current Settlement',
            icon: 'building',
            iconSize: 16,
            component: statements.index,
            exact: true,
            hideInMenu: true,
          },
          {
            path: '/current-statement',
            label: 'Current Statement Period',
            icon: 'building',
            iconSize: 16,
            component: statements.index,
            exact: true,
            hideInMenu: true,
          },
          { path: '/completed', label: 'Completed Settlements', icon: 'building', iconSize: 16, component: statements.index, exact: true, hideInMenu: true },
          { path: '/all', label: 'All Statements', icon: 'building', iconSize: 16, component: statements.index, exact: true },
          { path: '/order-payments', label: 'Order Payments', icon: 'building', iconSize: 16, component: statements.orders, exact: true },

          // { path: '/additional', label: 'Additional Documents', icon: 'folder', component: account.accountDetail },
          // { path: '/pickup', label: 'Warehouse Address / Pickup Location', icon: 'pin', component: account.accountDetail },
        ],
      },
      {
        path: `${appRoot}/promotions/list`,
        label: 'menu.promotions',
        icon: 'trend-up',
        exact: true,
        redirect: true,
        roles: [USER_ROLE.SuperAdmin, USER_ROLE.CustomerSupport],
        //  hideInMenu: false,
        showWhen: (pathname) => {
          return pathname?.indexOf('promotions') > -1;
        },
        to: `${appRoot}/promotions/list/coupon`,
        subs: [
          {
            path: '/coupon',
            label: 'menu.coupons',
            icon: 'bookmark',
            component: promotions.coupons,
            subs: [{ path: '/detail/:id', label: 'menu.orders-detail', icon: 'file-empty', component: promotions.couponDetail, hideInMenu: true }],
          },
          {
            path: '/auto-applied',
            label: 'menu.promotions-auto-applied',
            icon: 'bookmark',
            component: promotions.list,
            subs: [{ path: '/detail/:id', label: 'menu.orders-detail', icon: 'file-empty', component: promotions.detail, hideInMenu: true }],
          },
          {
            path: '/coupon-logs',
            label: 'menu.couponLogs',
            icon: 'bookmark',
            component: promotions.couponLogs,
          },
          {
            path: '/campaigns',
            label: 'Campaigns',
            icon: 'board-1',
            component: marketing.adsPlatform,
            exact: true,
            subs: [
              { path: '/detail/:id', exact: true, label: 'ads.campaignDetails', icon: 'email', hideInMenu: true, component: marketing.adsCampaignDetails },
            ],
          },
        ],
      },
      {
        path: `${appRoot}/studio`,
        label: 'menu.company_catalog',
        icon: 'grid-1',
        exact: true,
        roles: [USER_ROLE.SuperAdmin, USER_ROLE.CustomerSupport, USER_ROLE.ContentTeamAdmin, USER_ROLE.studioAdmin],
        redirect: true,
        to: `${appRoot}/studio/partner`,

        showWhen: (pathname) => {
          return pathname?.indexOf('studio') > -1;
        },
        // redirect: true,
        // to: `${appRoot}/catalog/partner`,

        subs: [
          {
            path: '/partner',
            label: 'menu.products',
            icon: 'database',
            component: catalog.products,
          },
          {
            path: '/studio-images',
            label: 'menu.studio-images',
            icon: 'file-image',
            component: catalog.studioImages,
          },
          // {
          //   path: '/ideas',
          //   label: 'Ideas',
          //   icon: 'file-image',
          //   component: catalog.ideaImages,
          // },
          {
            path: '/unlisted-products',
            label: 'menu.unlisted-products',
            icon: 'list',
            component: catalog.unlistedStudioProducts,
          },
        ],
      },
      {
        path: `${appRoot}/errors/list`,
        label: 'Errors',
        icon: 'trend-up',
        exact: true,
        redirect: true,
        roles: [USER_ROLE.SuperAdmin, USER_ROLE.CustomerSupport],
        hideInMenu: false,

        to: `${appRoot}/errors/list/all`,
        subs: [
          {
            path: '/all',
            label: 'All',
            icon: 'bookmark',
            exact: true,
            component: sellers.errors,
          },
          {
            path: '/critical',
            label: 'Critical',
            icon: 'bookmark',
            exact: true,
            component: sellers.errors,
          },
        ],
      },

      {
        path: `${appRoot}/consignments`,
        label: 'Consignments',

        exact: true,
        redirect: true,
        icon: 'bookmark',
        //  hideInMenu: false,
        showWhen: (pathname) => {
          return pathname?.indexOf('consignments') > -1;
        },
        to: `${appRoot}/consignments/lpo-list`,
        subs: [
          {
            path: '/lpo-list',
            label: 'LPO List',
            component: consignments.lpoList,
            exact: true,
          },
          {
            path: '/lpo-details',
            label: 'New LPO',
            exact: true,
            component: consignments.lpoDetails,
          },
          {
            path: '/lpo-details/:id',
            label: 'New LPO',
            exact: true,
            component: consignments.lpoDetails,
          },
        ],
      },
      {
        path: `${appRoot}/penalty`,
        label: 'Penalties',
        icon: 'trend-up',

        redirect: true,
        showWhen: (pathname) => {
          return pathname.includes('penalty');
        },
        to: `${appRoot}/penalty/list`,
        subs: [
          {
            path: '/penalty/list',
            label: 'Penalties',
            icon: 'trend-up',
            component: penalty.penaltyList,
            exact: true,
            subs: [],
          },
        ],
      },

      {
        path: `${appRoot}/marketing`,
        label: 'marketing',
        icon: 'trend-up',
        redirect: true,
        exact: true,
        roles: [USER_ROLE.SuperAdmin],
        to: `${appRoot}/marketing/campaigns`,
        showWhen: (pathname) => {
          return pathname?.indexOf('marketing') > -1;
        },
        subs: [
          {
            path: '/campaigns',
            label: 'Campaigns',
            icon: 'board-1',
            component: marketing.adsPlatform,
            exact: true,
            subs: [
              { path: '/detail/:id', exact: true, label: 'ads.campaignDetails', icon: 'email', hideInMenu: true, component: marketing.adsCampaignDetails },
            ],
          },
        ],
      },

      // {
      //   path: `${appRoot}/b2b-promotions`,
      //   label: 'B2B Promotions',
      //   icon: 'trend-up',
      //   exact: true,
      //   showWhen: (pathname) => {
      //     return pathname?.indexOf('b2b-promotions') > -1 || pathname?.indexOf('returns') > -1;
      //   },
      //   to: `${appRoot}/b2b-promotions`,
      //   subs: [
      //     {
      //       path: `/banners/slots`,
      //       label: `Banner Slots`,
      //       icon: 'trend-up',
      //       exact: true,
      //       component: b2bPromotions.bannerSlots,
      //     },
      //     {
      //       path: `/banners/pending-approval`,
      //       label: `Banners Pending Approval`,
      //       icon: 'trend-up',
      //       exact: true,
      //       component: b2bPromotions.bannersPendingApproval,
      //     },
      //   ],
      // },
    ],
  };
};
export default routesAndMenuItems;
