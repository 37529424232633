import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';

const ProductFieldReceommendation = lazy(() => import('views/catalog/detail/ProductFieldReceommendation'));

const NotFound = lazy(() => import('views/default/NotFound'));
const SupportTicketWidget = lazy(() => import('views/support/SupportTicketsDetail'));
const Login = lazy(() => import('views/default/Login'));
const ForgotPassword = lazy(() => import('views/default/ForgotPassword'));
const Register = lazy(() => import('views/default/Register'));
const ResetPassword = lazy(() => import('views/default/ResetPassword'));
const Unauthorized = lazy(() => import('views/default/Unauthorized'));
const EmailVerificationWaiting = lazy(() => import('views/default/EmailVerificationWaiting'));
const InvalidAccess = lazy(() => import('views/default/InvalidAccess'));
const App = lazy(() => import('App.js'));
const Home = lazy(() => import('views/default/Home'));

/*
{ path: "/path", exact: true, component: ViewHome },
// or
{ path: "/path", component: ViewHome},
// or
{ path: "/path", exact: true, redirect: true, to: "/redirectPath" },
*/
const defaultRoutes = [
  { path: DEFAULT_PATHS.NOTFOUND, exact: true, component: NotFound },
  { path: DEFAULT_PATHS.LOGIN, exact: true, component: Login },
  { path: DEFAULT_PATHS.REGISTER, exact: true, component: Register },
  { path: DEFAULT_PATHS.FORGOT_PASSWORD, exact: true, component: ForgotPassword },
  { path: DEFAULT_PATHS.RESET_PASSWORD, exact: true, component: ResetPassword },
  { path: DEFAULT_PATHS.UNAUTHORIZED, exact: true, component: Unauthorized },
  { path: DEFAULT_PATHS.EMAIL_VERIFICATION_WAITING, exact: true, component: EmailVerificationWaiting },
  { path: DEFAULT_PATHS.SUPPORT_TICKET_WIDGET, component: SupportTicketWidget, exact: true },
  { path: DEFAULT_PATHS.INVALID_ACCESS, exact: true, component: InvalidAccess },
  { path: DEFAULT_PATHS.PRODUCT_RECOMMENDATION, exact: true, component: ProductFieldReceommendation },
  { path: DEFAULT_PATHS.APP, component: App },

  { path: '/', exact: true, component: Home },
];

export default defaultRoutes;
