import React, { memo, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { DEFAULT_PATHS } from 'config.js';
import { userHasPrivilege } from 'routing/helper';
import RouteItem from './RouteItem';

const RouteIdentifier = ({ routes, role, fallback = <div className="loading" />, notFoundPath = DEFAULT_PATHS.NOTFOUND }) => {
  console.log('routesroutesroutesroutes', routes);

  return (
    <Suspense fallback={fallback}>
      <Switch>
        {routes.map((route, rIndex) => {
          const privilege = userHasPrivilege(role, route.path);
          if (role && privilege) {
            return <RouteItem key={`r.${rIndex}`} {...route} />;
          }
          if (!role) {
            return <RouteItem key={`r.${rIndex}`} {...route} />;
          }
          return null;
        })}
        <Redirect to={notFoundPath} />
      </Switch>
    </Suspense>
  );
};

export default memo(RouteIdentifier);
