import { createSlice } from '@reduxjs/toolkit';

// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  dashboardStatus: [],
  isdashboardLoading: true,
};

const dashboardStatusSlice = createSlice({
  name: 'dashboardStatus',
  initialState,
  reducers: {
    setDashboardStatus(state, actions) {
      state.isdashboardLoading = false;
      state.dashboardStatus = actions.payload;
    },
  },
});

export const { setDashboardStatus } = dashboardStatusSlice.actions;

const dashboardStatusReducer = dashboardStatusSlice.reducer;
export default dashboardStatusReducer;
