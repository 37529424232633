import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { dhGetAuth } from 'helpers/Firebase';

import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: (req) => {
    return `${process.env.SELLER_HUB_CATALOG_GRAPHQL_API}/graphql?operationName=${req.operationName}&platform=spade`;
  },
});
const authLink = setContext((_, { headers }) => {
  // it will always get unexpired version of the token
  return dhGetAuth()
    .currentUser.getIdToken()
    .then((token) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
          'x-marketplace': '1',
        },
      };
    });
});
// const link = ApolloLink.from([authLink, ...{/**ur other links */}])
const link = ApolloLink.from([
  authLink,
  httpLink,
  onError(({ graphQLErrors, networkError }) => {
    console.log('🚀 ~ file: ApolloClientUploadService.js ~ line 31 ~ onError ~ graphQLErrors', graphQLErrors);
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
    }
  }),
]);

const ApolloClientCatalogService = new ApolloClient({
  link,
  // uri: 'http://localhost:4001/graphql',
  cache: new InMemoryCache({
    addTypename: false,
  }),
  errorPolicy: 'ignore',
});
export default ApolloClientCatalogService;
