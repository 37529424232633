export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
  SuperAdmin: 'superAdmin',
  Editor: 'editor',
  Finance: 'finance',
  Audit: 'audit',
  Accounts: 'accounts',
  CustomerSupport: 'customerSupport',
  ContentTeamAdmin: 'contentTeamAdmin',
  studioAdmin: 'studioAdmin',
  WarehouseTeam: 'warehouse',
  CatalogTeam: 'catalog',
};
export const COUNTRIES_OPTIONS = [
  {
    value: 'ae',
    label: 'UAE',
  },
  {
    value: 'qa',
    label: 'QAT',
  },
  {
    value: 'bh',
    label: 'BAH',
  },
  {
    value: 'om',
    label: 'OMN',
  },
  {
    value: 'kw',
    label: 'KWT',
  },
];

export const COUNTRIES = [
  {
    id: 'uae',
    code: 'UAE',
    value: 'uae',
    label: 'UAE',
    iso2_code: 'AE',
    flag: '🇦🇪',
    currency: 'AED',
  },
  {
    id: 'qat',
    code: 'QAT',
    value: 'qat',
    label: 'QAT',
    iso2_code: 'QA',
    flag: '🇶🇦',
    currency: 'QAR',
  },
  {
    id: 'bah',
    code: 'BAH',
    value: 'bah',
    label: 'BAH',
    iso2_code: 'BH',
    flag: '🇧🇭',
    currency: 'BHD',
  },
  {
    id: 'omn',
    code: 'OMN',
    value: 'omn',
    label: 'OMN',
    iso2_code: 'OM',
    flag: '🇴🇲',
    currency: 'OMR',
  },
  {
    id: 'ksa',
    code: 'KSA',
    value: 'ksa',
    label: 'KSA',
    iso2_code: 'SA',
    flag: '🇸🇦',
    currency: 'SAR',
  },
  {
    id: 'kwt',
    code: 'KWT',
    value: 'kwt',
    label: 'KWT',
    iso2_code: 'KW',
    flag: '🇰🇼',
    currency: 'KWD',
  },
];
export const WAREHOUSES = {
  UAE: [
    { id: 'uae_jebel_ali', name: 'Jebel Ali' },
    { id: 'uae_jafza', name: 'Jafza' },
  ],
  QAT: [
    { id: 'qat_jebel_ali', name: 'Jebel Ali' },
    { id: 'qat_jafza', name: 'Jafza' },
  ],
  OMN: [
    { id: 'OMN_jebel_ali', name: 'Jebel Ali' },
    { id: 'OMN_jafza', name: 'Jafza' },
  ],
  BAH: [
    { id: 'BAH_jebel_ali', name: 'Jebel Ali' },
    { id: 'BAH_jafza', name: 'Jafza' },
  ],
  KSA: [
    { id: 'BAH_jebel_ali', name: 'Jebel Ali' },
    { id: 'BAH_jafza', name: 'Jafza' },
  ],
};

export const MARKETPLACES = [
  { id: 'DanubeHome', name: 'DANUBE HOMES', logo: 'https://cdn.danubehome.com/media/logo/stores/1/Online-Logo_02.jpg' },
  { id: 'Houzz', name: 'Houzz', logo: 'https://1000logos.net/wp-content/uploads/2017/08/Houzz-Logo.png' },
];

export const SORTING_ORDER = {
  ASC: 1,
  DESC: -1,
};

export const INPUT = {
  SELECT: 'select',
  TEXT: 'text',
  BOOLEAN: 'boolean',
  PRICE: 'price',
  NUMERIC: 'numeric',
  TEXTAREA: 'textarea',
  DATE: 'date',
};
export const INPUT_TYPES_LIST = [
  {
    value: INPUT.SELECT,
    label: 'Dropdown',
  },
  {
    value: INPUT.TEXT,
    label: 'Text',
  },
  {
    value: INPUT.BOOLEAN,
    label: 'Boolean / Switch',
  },

  {
    value: INPUT.PRICE,
    label: 'Price',
  },
  {
    value: INPUT.NUMERIC,
    label: 'Numeric',
  },
  {
    value: INPUT.TEXTAREA,
    label: 'Textarea / Paragraph',
  },
];

export const APPROVAL_STATUS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};
export const SELLER_APPROVED_VALUES = [
  { value: 1, label: 'Permanently Blocked' },
  { value: 2, label: 'Active' },
  { value: 3, label: 'Temporarily Suspended' },
  { value: 4, label: 'Onboarding Verification' },
  { value: 5, label: 'Catalog Suspended' },
];
export const DAY_VALUES = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

export const GRADES_BADGE = {
  EXCELLENT: 'success',
  POOR: 'danger',
  GOOD: 'secondary',
  'VERY GOOD': 'success',
  'NEEDS IMPROVEMOVENT': 'warning',
  'Coming Soon': 'outline-info',
};

export const OFFER_OPTIONS = [
  { value: '', label: 'Please select' },
  { value: 'default', label: 'Default' },
  { value: 'sale', label: 'On Sale' },
  { value: 'further_reduction', label: 'Further Reduction' },
  { value: 'lightening', label: 'Lightening Deal' },
];

export const CUSTOMER_OPTIONS = [
  { value: '', label: 'Please select' },
  { value: 'all', label: 'All Customers' },
  { value: 'vip', label: 'VIP Customers' },
];

export const DEFAULT_CUSTOMER_GROUP = 'all';

export const DEFAULT_OFFER_ID = 'default';
export const OFFER_SALE_ID = 'sale';

export const DEFAULT_COUNTRY = 'AE';
export const DEFAULT_CURRENCY = 'AED';

export const DEFAULT_PRICE_RANGE = [0, 100000];

export const DEFAULT_COLOR = '#0000';

export const IS_NEW = 'new';

export const IMPORT_STATUS = {
  Success: 'Success',
  Failed: 'Failed',
};
export const OMS_API = {
  // BASE_URL: 'http://localhost/dh-seller/public/api/v1'
  // BASE_URL: 'http://172.18.2.204/dh-seller/public/api/v1/'
  BASE_URL: process.env.SELLER_HUB_OMS_LINK,
};
export const STATUS_CUSTOMER_CANCELLED = 100;

export const STATUS_COLOR = [
  {
    lsStatus: 'Pending',
    color: 'muted',
    id: '0',
  },
  {
    lsStatus: 'Accepted',
    color: 'success',
    id: '1',
  },
  {
    lsStatus: 'Ready to pickup',
    color: 'info',
    id: '2',
  },
  {
    lsStatus: 'Picked',
    color: 'quaternary',
    id: '3',
  },
  {
    lsStatus: 'Delivered',
    color: 'success',
    id: '4',
  },
  {
    lsStatus: 'Return',
    color: 'danger',
    id: '5',
  },
  {
    lsStatus: 'Rejected By Seller',
    color: 'danger',
    id: '6',
  },
  {
    lsStatus: 'Invoice pending',
    color: 'warning',
    id: '9',
  },
  {
    lsStatus: 'Un Delivered',
    color: 'warning',
    id: '10',
  },
  {
    lsStatus: 'Delivery Failed',
    color: 'warning',
    id: '12',
  },
  {
    lsStatus: 'Completed',
    color: 'success',
    id: '8',
  },
  {
    lsStatus: 'Partially Accepted',
    color: 'info',
    id: '7',
  },
  {
    lsStatus: 'Cancelled by Customer',
    color: 'danger',
    id: STATUS_CUSTOMER_CANCELLED,
  },
  {
    lsStatus: 'Cancelled by Seller',
    color: 'danger',
    id: '200',
  },
  {
    lsStatus: 'Out for Delivery',
    color: 'info',
    id: '11',
  },
];

export const STATUS_PENDING = 0;
export const STATUS_ACCEPTED = 1;
export const STATUS_DELIVERED = 4;
export const STATUS_READY_TO_PICKUP = 2;
export const STATUS_PICKED = 3;
export const STATUS_REJECT = 6;
export const STATUS_ALL = 20;

export const COMMENT_DISPLAY_DATE_FORMAT = 'DD MMM YYYY h:m a';

export const BASE_ID_RELATED_BY = [
  { label: 'Color', value: 'color' },
  { label: 'Mattress Size', value: 'mattress_size' },
  { label: 'Pattern Size', value: 'pattern_size' },
  { label: 'Carpet Size', value: 'carpet_size' },
];

export const FIELDS_NOT_EXIST = ['fixing_time', 'cbm', 'is_double_cabin'];

export const CORE_COMPANY = 'DANUBEHOMEONLINE';

export const IMAGE_TAGS = [
  { label: 'Life Style', value: 1 },
  { label: 'Still Shoot', value: 2 },
];

export const SUPPORT_TICKET_STATUS = ['opened', 'pending', 'reopened', 'resolved', 'cancellation / returns', 'escalated'];
export const SUPPORT_TICKET_STATUS_BG = {
  opened: { bg: 'info', subTitle: 'New' },
  pending: { bg: 'info', subTitle: 'Working On' },

  resolved: { bg: 'success' },
  'cancellation / returns': { bg: 'tertiary' },
  escalated: { bg: 'danger' },
  reopened: { bg: 'warning', subTitle: '' },
};

export const ACCOUNT_HEALTH_WEIGHTAGE = {
  delayed_acceptance: 2,
  delayed_message_reply: 3,
  delayed_support_ticket_resolution: 2,
  track_sheet_missed: 3,
  breached_delivery_date: 2,
  order_rejection: 3,
  order_defect: 3,
  late_pod_upload: 1,
  late_invoice_upload: 1,
  product_not_as_described: 3,
  product_negative_comments: 2,
  product_negative_ratings: 2,
  delivery_negative_ratings: 2,
  seller_negative_ratings: 2,
  danube_gaurantee_claims: 2,
  other_process_voilation: 2,
  total: 35,
};
