import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  isLogin: false,
  currentUser: {},
  isLoginLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      state.isLogin = true;
      state.isLoginLoading = false;
      state.emailVerificationSuccessful = !!state.currentUser?.emailVerified;
    },
    setLogout(state) {
      state.currentUser = {};
      state.isLogin = false;
      state.isLoginLoading = false;
    },
    setUserRole(state, action) {
      state.currentUser.role = action.payload.role;
    },
    setUserPrivilege(state, action) {
      state.currentUser.privilege = action.payload;
    },
  },
});

export const { setCurrentUser, setLogout, setUserRole, setUserPrivilege } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
