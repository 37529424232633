import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  list: [],
  listV2: [],
};

const returnSlice = createSlice({
  name: 'returns',
  initialState,
  reducers: {
    setReturnList(state, actions) {
      state.list = actions.payload;
    },
    setReturnListV2(state, actions) {
      const returns = actions.payload.returnList.listReturns?.map((order) => {
        return {
          ...order,
          created_at: format(new Date(new Date(Number(order.created_at))), 'dd/MM/yyyy'),
          credit_note_date: order?.credit_note_date && format(new Date(new Date(Number(order.credit_note_date))), 'dd/MM/yyyy') || '',
          refund_date: order?.refund_date && format(new Date(new Date(Number(order.refund_date))), 'dd/MM/yyyy') || '',
          supplier_return_date: order?.supplier_return_date && format(new Date(new Date(Number(order.supplier_return_date))), 'dd/MM/yyyy') || '',
        };
      });
      state.listV2 = {
        listReturns: returns,
        listReturnsCount: actions.payload.returnList?.listReturnsCount,
        returnSummary: actions.payload.returnList?.returnSummary,
        returnSupplierSummary: actions.payload.returnList?.returnSupplierSummary,
      };
    },
  },
});

export const { setReturnList, setReturnListV2 } = returnSlice.actions;
const returnReducer = returnSlice.reducer;

export default returnReducer;
