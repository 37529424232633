import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  list: [],
  isLoading: true,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderList(state, actions) {
      state.isLoading = false;
      state.list = actions.payload;
    },
    setOrderListV2(state, actions) {
      state.isLoading = false;
      const orders = actions.payload.orderList.listOrders?.map((order) => {
        let deliveryInfo;
        try {
          deliveryInfo = (order?.delivery_info !== NULL && order?.delivery_info !== '' && JSON.parse(order?.delivery_info)) || {};
        } catch (e) {
          console.log(e);
        }
        return {
          ...order,
          deliveryInfo,
          created_at: format(new Date(new Date(Number(order.created_at))), 'dd/MM/yyyy h:mm a'),
          reference_no_with_serial_number: `1. ${order.reference_no}`,
        };
      });
      state.listV2 = {
        listOrders: orders,
        listOrdersCount: actions.payload.orderList?.listOrdersCount,
        deliveryScheduleSummary: actions.payload.orderList?.deliveryScheduleSummary,
      };
      if (actions.payload.orderList?.orderStatusSummary) {
        const statuses = {};
        actions.payload.orderList?.orderStatusSummary.forEach((status) => {
          statuses[status.order_status] = status.statusCount;
        });
        state.statusSummary = statuses;
      }
    },
    setInvoicePendingFilter(state, actions) {
      const orderList = state.listV2.listOrders.filter((item) => item.order_status === 4 && (item.invoice_no === '' || item.invoice_no === null));
      state.listV2.listOrders = orderList;
      const count = orderList?.length || 1;
      const limit = actions?.payload?.limit || 1;
      const totalPages = Math.ceil(count / limit);
      state.listV2.listOrdersCount.totalPages = totalPages;
    },
  },
});

export const { setOrderList, setOrderListV2, setInvoicePendingFilter } = orderSlice.actions;
const orderReducer = orderSlice.reducer;

export default orderReducer;
