import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  rejectStatus: [],
  isRejectLoading: true,
};

const rejectStatusSlice = createSlice({
  name: 'rejectStatus',
  initialState,
  reducers: {
    setRejectStatus(state, actions) {
      state.isRejectLoading = false;
      state.rejectStatus = actions.payload;
    },
  },
});

export const { setRejectStatus } = rejectStatusSlice.actions;

const rejectStatusReducer = rejectStatusSlice.reducer;
export default rejectStatusReducer;
